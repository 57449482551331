import axios, { AxiosError, AxiosInstance } from 'axios'
import { removeCookie } from 'helpers/cookies'
import { getCountryId, getCountryKey, getLanguageId } from './country'
import { getPublicHeader, serviceType, getFetchUrl } from './constants'
import { message } from 'antd'
import { getLang } from 'helpers/country'
import { isServer } from './utilities'
import { languages } from './country.base'

const log = process.env.NODE_ENV == 'development' ? console.log : () => void 0
type publicOrProtected = 'public' | 'protected'

function getHeader() {
  const header = {
    ...getPublicHeader(),
  }
  return header
}

// Create Axios instance
function createAxiosInstance(baseURL, headers) {
  return axios.create({ baseURL, headers })
}

// Main function
function configuredAxios(
  serviceType: serviceType,
) {
  // Validate
  if (!serviceType) throw Error('serviceType is required')

  // Get header
  const header = getHeader()

  // Create and return axios
  const url = getFetchUrl(serviceType)
  return createAxiosInstance(url, header)
}

type LanguageItem = typeof languages[0]

const chain = (serviceType: serviceType, language?: Partial<LanguageItem> | undefined): AxiosInstance => {
  let axios: AxiosInstance = null
  axios = configuredAxios(serviceType)

  // 添加请求拦截器
  axios.interceptors.request.use(async (config) => {
    const defaultHeaders = {}
    try {
      const hasHttp = /^https?:\/\//.test(config.baseURL)
      const _baseUrl = !hasHttp && !isServer() ? window.location.origin + '' + config.baseURL : config.baseURL
      const url = new URL(_baseUrl + '' + config.url)
      log(`%c[${new Date().toLocaleString()}]: axios request ${url.pathname}`, 'color:red;', config)

    } catch (error) { }
    let countryKey = undefined
    let languageCode = language?.id || ''
    if (typeof window === 'undefined') {
      const result = await import('next/headers')
        .then(result => {
          const headers = result.headers() as any;
          const host = headers.get('host') || headers.get(':authority');
          // const cookieHeader = headers.get('cookie') || '';
          const cookies = result.cookies()
          const sessionId = cookies.get('sessionid')?.value; // 解析cookie获取sessionid
          config.headers['Cookie'] = headers.headers?.cookie ?? ''
          const countryKey = getCountryKey(host)
          return { sessionId, countryKey };
        })
      countryKey = result.countryKey
    }

    if (!config.headers.hasOwnProperty('Country-Id')) {
      const countryId = getCountryId(countryKey)
      defaultHeaders['Country-Id'] = '' + countryId;
    }

    if (!languageCode) {
      languageCode = getLanguageId()
    }

    defaultHeaders['Language-Id'] = '' + languageCode

    config.headers = {
      ...defaultHeaders,
      ...config.headers,
    };

    return config
  })


  // 添加响应拦截器
  axios.interceptors.response.use((response) => {
    try {
      const hasHttp = /^https?:\/\//.test(response.config.baseURL)
      const _baseUrl = !hasHttp && !isServer() ? window.location.origin + '' + response.config.baseURL : response.config.baseURL
      const url = new URL(_baseUrl + '' + response.config.url)
      log(`%c[${new Date().toLocaleString()}]: axios response ${url.pathname}`, 'color: green;', response)
    } catch (error) {
      console.log('request error', error)
    }
    return response
  },
    async (error: AxiosError) => {
      // logError(error?.response?.data ?? error?.response ?? error)
      const originErrorResponse = error?.response
      const statusCode = error?.response?.status

      if (statusCode === 401) {
        removeCookie('sessionid')
        // eslint-disable-next-line prefer-promise-reject-errors
        if (typeof window !== 'undefined') {
          window.location.href = '/get-started'
        } else {
          const { redirect } = await import('next/navigation')
          redirect('/get-started')
        }
        // return Promise.reject([error, originErrorResponse])
      }
      else {
        // fix the problem of saga overwriting the original axios error 
        // eslint-disable-next-line prefer-promise-reject-errors
        console.log(error, originErrorResponse)
        if (originErrorResponse?.data?.message && typeof window != 'undefined') {
          message?.error(originErrorResponse?.data?.message)
        }
        return Promise.reject(originErrorResponse)
        //  return Promise.reject([error, originErrorResponse])
      }
    });
  return axios
}
function parseCookies(cookieString) {
  return cookieString.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map(c => c.trim());
    cookies[name] = value;
    return cookies;
  }, {});
}
export default chain
